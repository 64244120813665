import React from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import {
  Div,
  DropdownLink,
  Flex,
  Link,
  QuantumThemeWrapper,
  Spacer,
  StaticNavigation,
  Text,
  UserCard,
  UserCardLoader,
} from '@sendoutcards/quantum-design-ui'
import { StorefrontState } from 'src/redux/reducers/storefront'
import { SponsorFragment } from 'src/graphql'
import AccountSection from './AccountSection'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type StaticNavProps = {
  storefront: StorefrontState
}

const StaticNav: React.FC<StaticNavProps> = props => {
  const { storefront } = props

  const isLoading = storefront.loading && !storefront.storefront?.user
  const hasSponsor = storefront.storefront?.user.genealogyId

  const width = useWindowWidth()
  const isMobile = width < 600

  const drawerOffset = hasSponsor ? 100 : 60

  const getSponsorSuffix = (sponsor: SponsorFragment | undefined): string => {
    if (!sponsor) return ''
    return sponsor.genealogyId
      ? `${sponsor.genealogyId}`
      : sponsor.storefront && sponsor.storefront.slug
      ? `${sponsor.storefront.slug}`
      : ''
  }

  const promptingsIcon = (
    <Flex
      cursor="pointer"
      height="60px"
      width="183px"
      onClick={() =>
        window.open(
          `https://www.promptings.com/${
            hasSponsor
              ? `?sponsor=${getSponsorSuffix(storefront.storefront?.user)}`
              : ''
          }`,
        )
      }
    >
      <Icon name={'promptings_powered_by_soc'} />
    </Flex>
  )

  const links: (DropdownLink | Link)[] = [
    {
      title: 'Promptings',
      href: `https://www.promptings.com/?sponsor=${getSponsorSuffix(
        storefront.storefront?.user,
      )}`,
    },
    {
      title: '3 Card Sampler',
      href: `https://www.promptings.com/3cardsampler/?sponsor=${getSponsorSuffix(
        storefront.storefront?.user,
      )}`,
    },
    {
      title: 'Pricing',
      href: `https://app.sendoutcards.com/pricing${
        storefront.storefront?.user
          ? '?sponsor=' + getSponsorSuffix(storefront.storefront?.user)
          : ''
      }`,
    },
  ]
  return (
    <QuantumThemeWrapper>
      <Div position="fixed" width="100%" zIndex={102} minWidth="320px">
        {hasSponsor && (
          <Flex
            inset={{ left: 'x3', top: 'x1' }}
            minHeight="100px"
            position="relative"
            backgroundColor="background"
            flexDirection="column"
            zIndex={22}
          >
            <Flex
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
            >
              {isLoading ? (
                <UserCardLoader size="small" />
              ) : (
                <>
                  <UserCard
                    profileImage={
                      storefront.storefront?.user.storefront?.mugshot?.url ??
                      storefront.storefront?.user.profileImageUrl ??
                      ''
                    }
                    firstName={storefront.storefront?.user.firstName ?? ''}
                    lastName={storefront.storefront?.user.lastName ?? ''}
                    caption={`Thanks for visiting my ${
                      storefront.storefront?.user.isAffiliate
                        ? 'consultant'
                        : 'referral'
                    } site.`}
                    size="small"
                  />
                  <Spacer orientation="horizontal" space="x2" />
                  {!isMobile && (
                    <Text type="body">
                      {storefront.storefront?.user.storefront?.biography}
                    </Text>
                  )}
                </>
              )}
            </Flex>
            {isMobile && (
              <Flex justifyContent="center" alignItems="center">
                <Text type="body">
                  {storefront.storefront?.user.storefront?.biography}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        <StaticNavigation
          links={links}
          openValueOverride={drawerOffset}
          logo={promptingsIcon}
          drawerOffset={drawerOffset}
          accountSection={<AccountSection />}
        />
      </Div>
    </QuantumThemeWrapper>
  )
}

export default StaticNav
